import { Text } from 'components';
import AppleStoreButton from 'pages/start/success/components/AppleStoreButton';
import GooglePlayButton from 'pages/start/success/components/GooglePlayButton';
import React, { FC } from 'react';
import styled from 'styled-components';
import { mobile, smMobile, tablet } from 'styles/breakpoints';

interface RegisterCompletedProps {}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${mobile} {
    margin-bottom: 3rem;
  }
`;

const Title = styled(Text)`
  font-size: 1rem;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #564789;
  margin-bottom: 9px;
  max-width: 320px;
`;

const Label = styled(Text)`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 1rem;
  max-width: 320px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 12px;
  @media ${tablet} {
    flex-direction: column;
  }
  @media ${mobile} {
    flex-direction: row;
  }
  @media ${smMobile} {
    gap: 4px;
  }
`;

const AppleStoreButtonStyled = styled(AppleStoreButton)`
  flex: 1;
  max-width: 163.5px;
`;
const GooglePlayButtonStyled = styled(GooglePlayButton)`
  flex: 1;
  max-width: 163.5px;
`;

const RegisterCompleted: FC<RegisterCompletedProps> = () => (
  <Container>
    <Title>You have successfully registered your account!</Title>
    <Label>
      Use your recently created Email and Password to log into the Lasting
      Change app. You can download the app using the links below:
    </Label>
    <ButtonContainer>
      <AppleStoreButtonStyled />
      <GooglePlayButtonStyled />
    </ButtonContainer>
  </Container>
);

export default RegisterCompleted;
