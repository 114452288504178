export enum SocialLinks {
  Instagram = 'https://www.instagram.com/thelastingchangeofficial/',
  Facebook = 'https://www.facebook.com/thelastingchangeofficial',
  Twitter = '#',
  Reddit = '#',
}
export enum StoresLinks {
  AppleStore = 'https://apps.apple.com/us/app/lasting-change-habit-builder/id6456888481',
  GooglePlay = 'https://play.google.com/store/apps/details?id=com.thelastingchange.app&hl=en&gl=US',
}
