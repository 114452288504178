import React from 'react';

import GooglePlayLogo from 'assets/icons/googlePlay_badge.svg';

import styled from 'styled-components';

import { StoresLinks } from 'utils/constants/externalLinks';
import { smMobile } from 'styles/breakpoints';

const StyledLink = styled.a``;
const GoogletoreBadge = styled(GooglePlayLogo)`
  width-min: 163.5px;
  height-min: 47.3px;
  width: 100%;
  height: 100%;
  @media ${smMobile} {
    width: 131px;
    height: 38px;
  }
`;

const GooglePlayLinkBtn = ({ ...props }) => (
  <StyledLink href={StoresLinks.GooglePlay} target="_blank" {...props}>
    <GoogletoreBadge />
  </StyledLink>
);

export default GooglePlayLinkBtn;
